import { Tooltip } from "bootstrap";

export class BlazorInterop
{
	public initializeTooltips(): void
	{
		const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');

		tooltipTriggerList.forEach(x => new Tooltip(x));
	}
}