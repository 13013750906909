declare const grecaptcha: any;
type Deferred = { resolve: (value: any) => void, reject: (reason: string) => void };

export class GoogleReCaptchaService
{
	private static renderDeferred: Deferred;
	private static executeDeferred: Deferred;

	public renderAsync(elementId: string, siteKey: string): Promise<number>
	{
		const intervalId = setInterval(() =>
		{
			if (typeof grecaptcha !== 'undefined')
			{
				const widgetId = grecaptcha.render(elementId, {
					"sitekey": siteKey,
					"size": "invisible",
					"callback": (token: string) => GoogleReCaptchaService.executeDeferred.resolve(token),
					"error-callback": () => GoogleReCaptchaService.executeDeferred.reject("Error"),
					"expired-callback": () => GoogleReCaptchaService.executeDeferred.reject("Expired")
				});

				clearInterval(intervalId);
				GoogleReCaptchaService.renderDeferred.resolve(widgetId);
			}

		}, 100);

		return new Promise((resolve, reject) =>
		{
			GoogleReCaptchaService.renderDeferred = { resolve, reject };
		});
	}

	public executeAsync(widgetId: number): Promise<string>
	{
		if (typeof grecaptcha !== 'undefined')
		{
			grecaptcha.reset(widgetId);
			grecaptcha.execute(widgetId);
		}

		return new Promise((resolve, reject) =>
		{
			GoogleReCaptchaService.executeDeferred = { resolve, reject };
		});
	}
}