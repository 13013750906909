import "@popperjs/core";
import "bootstrap";
import { BlazorInterop } from "./blazor/index";
import { GoogleReCaptchaService } from "./blazor/googleReCaptchaService"
import "../styles/index.scss";

declare const APP_ENVIRONMENT: "Development" | "Production";

(() =>
{
	console.log(`ERM Admin System - ${APP_ENVIRONMENT}`);

	// eslint-disable-next-line
	// @ts-ignore
	window.ErmAdminBlazorInterop = new BlazorInterop();

	// eslint-disable-next-line
	// @ts-ignore
	window.GoogleReCaptchaInterop = new GoogleReCaptchaService();
})();